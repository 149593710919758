import * as React from 'react';

import * as classNames from 'classnames';
import * as DOMPurify from 'dompurify';

import { Button, Link, TextField, Typography } from '@emburse/embark-core';

import { Disclosure } from '@Components/Disclosure';
import { LogoContainer } from '@Components/layouts/LogoContainer';
import { injectCastleRequestToken } from '@ReactComponents/../utils/castle';

import { WindowErrors } from '@Client/loginWrapper';

import { emburseLoginEnabled } from './loginHelper';

import * as style from './LoginPage.styl';

interface ILoginPageProps {
  csrf: string;
  locals: WindowErrors;
}

interface IExtendedWindow extends Window {
  featureFlags: any;
}

export const LoginPage: React.FC<ILoginPageProps> = ({ csrf, locals }) => {
  const renderLoginForm = () => {
    return (
      <form method="POST" action="/login" id="login" autoComplete="off" onSubmit={onSubmit}>
        {(window as unknown as IExtendedWindow)?.FEATURES?.test_site ? (
          <Typography variant="h6" style={{ color: 'red' }} gutterBottom>
            THIS IS A TEST WEBSITE. PLEASE DON'T USE YOUR REAL CREDENTIALS TO LOGIN
          </Typography>
        ) : null}
        <input type="hidden" name="_csrf" value={csrf} />
        <TextField
          id="emailField"
          type="email"
          name="email"
          className="fullInput focus"
          placeholder="Email"
          required
          autoFocus
          fullWidth
          style={{ marginBottom: '20px' }}
        />
        <TextField
          id="passwordField"
          type="password"
          name="password"
          className="fullInput"
          placeholder="Password"
          autoComplete="off"
          fullWidth
          required
          style={{ marginBottom: '20px' }}
        />
        <Button id="loginButton" className="login" type="submit" size="small">
          Log In
        </Button>
        <div className="forgotContainer">
          <Link href="/reset_password">Forgot Password?</Link>
        </div>
      </form>
    );
  };

  const renderDowntime = () => {
    return (
      <div>
        {window?.whitelabelConfiguration?.tags?.brandName} is currently unavailable due to scheduled maintenance.
        {!window?.whitelabelConfiguration?.id && (
          <div>
            For more information see <a href="https://status.abacus.com">status.abacus.com</a>
          </div>
        )}
      </div>
    );
  };

  const createErrorDiv = (className: string, html: string) => {
    const sanitized = DOMPurify.sanitize(html); // this should be serverside html
    return <div className={className} dangerouslySetInnerHTML={{ __html: sanitized }} />;
  };

  const renderErrors = () => {
    let ele;
    if (locals?.error && locals.error.length) {
      ele = createErrorDiv('error', locals.error);
    } else if (locals?.flashError && locals.flashError.length > 0) {
      ele = createErrorDiv('error', locals.flashError);
    } else if (locals?.flashSuccess && locals.flashSuccess.length > 0) {
      ele = createErrorDiv('flashSuccess', locals.flashSuccess);
    }
    return ele;
  };

  function renderRedirectNotice() {
    return <div>Please wait while you are redirected to Emburse login </div>;
  }

  function renderMainContent() {
    if ((window as unknown as IExtendedWindow)?.FEATURES?.planned_database_downtime) {
      return renderDowntime();
    }

    if (window.FEATURES?.emburse_login && emburseLoginEnabled(window.location?.search)) {
      return renderRedirectNotice();
    }

    return renderLoginForm();
  }

  function onSubmit(e: any) {
    injectCastleRequestToken(e);
    e.nativeEvent.submitter.disabled = true;
    setTimeout(() => {
      e.nativeEvent.submitter.disabled = false;
    }, 2000); // in case of an unexpected error (e.g network issue), allows the form to be submitted again after a short delay
  }

  return (
    <div className={style.global}>
      <div className="onboardingWrapper">
        {renderErrors()}
        <div className={classNames('logoContainer', `container-whitelabel-${window.whitelabelConfiguration.id.toLowerCase()}`)}>
          <LogoContainer style={{ padding: '0px' }} />
        </div>
        <div className="onboardingContainer">{renderMainContent()}</div>

        <div className="disclosureContainer">
          <Disclosure whitelabelConfiguration={window.whitelabelConfiguration} />
        </div>
      </div>
    </div>
  );
};
